var LoadBasic = function() {
    var handleInit = function() {
        $( '#single-select-field' ).select2( {
            theme: "bootstrap-5",
            width: $( this ).data( 'width' ) ? $( this ).data( 'width' ) : $( this ).hasClass( 'w-100' ) ? '100%' : 'style',
            placeholder: $( this ).data( 'placeholder' ),
        });
    }

    return {
        init: function () {
            handleInit();
        },
        initAjax: function () {
            handleInit();
            Metronic.initAjax();
        }
    };
}();